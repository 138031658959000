export default {
  header: {
    title: 'Zahlungsinformationen',
    title_enterprize: 'Zahlungsdaten hinzufügen',
    subtitle: 'Schritt  3/3',
  },

  form: {
    payment_method: 'Zahlungsmethode',
    order_summary: 'Zusammenfassung Ihrer Bestellung',
    promocode: {
      label: 'Rabatt-Code',
      hint: 'Geben Sie hier Ihren persönlichen Rabatt-Code ein',
      tooltip_1:
        'Mit der Einlösung eines kostendeckenden Rabatt-Codes, erhalten Sie kostenlosen Zugriff auf die Bildungsplattform wîse up. Wenn Sie einen Rabattcode einlösen, starten Sie direkt mit einer 30-tägigen Testphase, die Teil des kostenlosen Nutzungszeitraums ist. Nach Ablauf der 30 Tage erhalten Sie von wîse up eine automatische Benachrichtigung über die kostenlose Weiternutzung.',
      tooltip_2:
        '<b>Wichtig</b>: Sie müssen nach der Testphase keine Zahlungsmethode hinterlegen. Die Nutzung bleibt für ein Jahr kostenfrei, sofern Sie während der Registrierung einen kostendeckender Rabatt-Code eingelöst haben.',
      btn_show: 'Ich habe einen Rabatt-Code',
      btn_add: 'Anwenden',
      btn_delete: 'Löschen',
      success_add: 'Ihr Rabatt-Code wurde angewandt.',
      one_time_discount_hint:
        'Gutscheine gewähren einen einmaligen Rabatt. Nach 12 Monaten verlängert sich das Abonnement zum regulären Preis.',
    },
    continue: 'Fortfahren',
    name_on_card: 'Karteninhaber',
  },

  order: {
    business_type: 'Business-Konto',
    total: 'Gesamt',
    discount: 'Rabatt',
    promocode: 'Rabatt-Code',
    charging_start: 'Zahlungsbeginn',
    charging_start_text:
      'Wir belasten Ihre Kreditkarte, sobald die eingeladenen Nutzer Ihre Einladung akzeptieren.',
    charging_free_text:
      'Während Ihres Probemonats wird Ihre Kreditkarte noch nicht belastet. Das Probemonat beginnt mit Aktivierung der ersten Lizenz.',
    trial: '30 Tage Probemonat',
    continue: 'Zahlungspflichtig bestellen',
    count_license: '{count} Jahreslizenz wîse up',
    info: {
      VAT: 'Mehrwehrtsteuer (20%)',
      multipli_sign: ' × ',
      equal_sign: ' = ',
      count_month: ' 12 ',
      currency: '€',
    },
    info_header_coast: 'Kostenvorschau',
    expire: 'Gültigkeitsdauer bis',
  },

  unpaid_summary: {
    title: 'Zahlungsinformationen',
    amount_to_pay: 'Zu zahlender Betrag',
    discount_amount: 'Rabattbetrag',
    subtotal: 'Zwischensumme',
    vat: 'Mehrwehrtsteuer',
  },

  voucher_amount_disappear: {
    text: 'Achtung: der Wert Ihres Gutscheins ist höher als der Gesamtwert des Warenkorbs. Der Restbetrag verfällt beim Einlösen. Möchten Sie fortfahren?',
    btn_cancel: 'Abbrechen',
    btn_submit: 'Bestätigen',
  },

  voucher_is_not_activated: {
    title: 'Ihr Rabatt-Code wurde noch nicht angewandt',
    text: 'Bitte klicken Sie auf „Anwenden”, wenn Sie ihn jetzt anwenden möchten, oder auf „Abbrechen”, wenn Sie ihn ändern möchten.',
    btn_cancel: 'Abbrechen',
    btn_submit: 'Anwenden',
  },
}
