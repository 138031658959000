export default {
  header: {
    title: 'Unternehmen erstellen',
    sub_title: 'Schritt 1/3',
  },

  form: {
    org_name: 'Name der Organisation',
    org_name_alt: 'Name des Unternehmens',
    org_address: 'Straße und Hausnummer',
    country: 'Land',
    town: 'Ort',
    zip_code: 'Postleitzahl',
    org_uid: 'UID',
    wko_id: 'WKO Mitgliedsnummer',
    org_indastry_area: 'Branche',
    continue_btn: 'Weiter',
    optional_text: 'optional',
    basic_info: 'Grundlegende Informationen',
    address: 'Adresse',
    change_btn: 'Aktualisieren',
    org_individual: 'Straße und Hausnummer',
  },

  back_btn: 'Zurück',

  success: {
    header: 'Fast geschafft!',
    header_trial: 'Zahlungsdaten erfolgreich hinzugefügt',
    text: 'Melden Sie sich mit Ihrer E-Mail Adresse und Ihrem Passwort an, um die Registrierung abzuschließen.',
    text_trial: 'Vielen Dank, dass Sie sich für wîse up entschieden haben!',
    go_back: 'Registrierung abschließen',
    go_back_trial: 'Zurück zu wîse up',
  },

  invited_success: {
    header: 'Ihr Konto wurde erfolgreich erstellt',
    btn_success: 'wîse up - starten',
    header_2: 'Fast geschafft!',
    text: 'Melden Sie sich mit Ihrer E-Mail Adresse und Ihrem Passwort an, um die Registrierung abzuschließen.',
    btn_success_2: 'Registrierung abschließen',
  },

  enterprise_success: {
    header: 'Vielen Dank für Ihre Anfrage!',
    text: 'Wir haben Ihre Anfrage erhalten und werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.',
    btn_success: 'Zurück zur Startseite',
  },
}
